import { register } from '@netcentric/component-loader';
import VideoAnalytics from 'commons/libs/analytics/analytics.video';
import Hls from 'hls.js';
import config from './batcom-video.config';
import { isOnMobile } from '../../../commons/utils';

class Video {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.hasStarted = false;
    this.init();
  }

  init() {
    this.setRefs();
    this.handleM3u8Extension();
    this.setEventListeners();
    this.hideControlsOnMobile();
  }

  setRefs() {
    this.video = this.el.querySelector(`${config.selectors.video}, ${config.selectors.audio}`);
    this.errorOverlay = this.el.querySelector(config.selectors.errorOverlay);
    this.playButton = this.el.querySelector(config.selectors.playButton);
    this.infoButton = this.el.querySelector(config.selectors.infoButton);
    this.info = this.el.querySelector(config.selectors.info);
    this.videoAnalytics = new VideoAnalytics(this.el);
  }

  setEventListeners() {
    this.video.addEventListener('error', (error) => this.videoErrorHandler(error));
    // Audio element does not have playButton neither infoButton
    if (this.playButton) {
      // Click anywhere in the poster image
      this.video.addEventListener('click', () => this.playVideo(), { once: true });
      this.playButton.addEventListener('click', () => this.playVideo());
      this.video.addEventListener('pause', () => {
        if (!isOnMobile()) {
          this.playButton.classList.remove('hidden');
        }
      });
      this.video.addEventListener('play', () => {
        this.playButton.classList.add('hidden');
        this.showControls();
        if (this.config.fullScreen) {
          this.requestFullScreen();
        }
      });
    }
    if (this.hls && !this.config.preload) {
      // Click in the ▶️ button of the native player
      this.video.addEventListener('play', () => {
        if (!this.hls.media) {
          this.playVideo();
        }
      }, { once: true });
    }
    if (this.infoButton) {
      this.infoButton.addEventListener('click', () => this.toggleVideoInfo());
    }
    // If the video plays from the beginning, track the 'playing' event
    this.video.addEventListener('playing', () => {
      if (!this.hasStarted) {
        this.trackEvent('start');
        this.hasStarted = true;
      }
    });
    // If the video reaches its end, track the 'ended' event
    this.video.addEventListener('ended', () => {
      this.trackEvent('end');
      this.hasStarted = false;  // Reset hasStarted when the video ends
    });
    // In all other cases, track 'play' and 'pause' events
    const manualActions = ['play', 'pause'];
    manualActions.forEach(action => {
      this.video.addEventListener(action, (event) => {
        const currentTime = this.video.currentTime;
        if (currentTime > 0 && currentTime < this.video.duration) {
          this.trackEvent(event.type);
        }
      });
    });
  }

  requestFullScreen() {
    if (this.video.requestFullscreen) {
      this.video.requestFullscreen();
    } else if (this.video.webkitRequestFullscreen) { // iOS
      this.video.webkitRequestFullscreen();
    }
  }

  trackEvent(eventType) {
    this.videoAnalytics.trackVideoEvent(eventType);
  }

  playVideo() {
    if (this.hls) {
      if (!this.hls.media) {
        this.hls.attachMedia(this.video);
      }
      // If videos are not preloaded we need to start quality level/fragment loading
      if (!this.config.preload && !this.hlsLoaded) {
        this.hls.startLoad();
        this.hlsLoaded = true;
      }
    }
    this.video.play();
    if (this.playButton) {
      this.playButton.classList.add('hidden');
    }
  }

  showControls() {
    this.video.setAttribute('controls', true);
  }

  hideControlsOnMobile() {
    if (isOnMobile() && this.playButton) {
      this.video.removeAttribute('controls');
    }
  }

  videoErrorHandler() {
    this.errorOverlay.style.display = 'block';
  }

  toggleVideoInfo() {
    this.info.classList.toggle(config.classes.open)
  }

  handleM3u8Extension() {
    const sourceElement = this.video.querySelector('source');
    const videoSource = this.video.src || (sourceElement ? sourceElement.src : '');

    if (videoSource.endsWith('.m3u8') && Hls.isSupported()) {
      this.hls = new Hls({
        autoStartLoad: this.config.preload,
        // Sets starting quality level to the maximum index of hls.levels
        startLevel: -1,
      });
      this.hls.loadSource(videoSource);
      if (this.config.preload) {
        this.hls.attachMedia(this.video);
      }
    }
  }
}

register({ Video });
