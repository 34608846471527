import Analytics from './analytics.js';
import { video } from './analytics.config.js';

export default class VideoAnalytics extends Analytics {
  trackVideoEvent(eventType) {
    const eventName = this.el.classList.contains(video.classes.audio) ? video.events.audio : video.events.video;
    const url = this.el.querySelector(video.selectors.media).getAttribute('src');
    const event = {
      event: eventName,
      eventAction: `${eventName} ${eventType}`,
      eventLabel: this.el.id,
      url,
      pageType: document.body.dataset.pageTitle,
      UserID: null,
    };
    this.pushAnalytics(event);
  }
}
