export default {
  selectors: {
    video: '.batcom-video__video',
    audio: '.batcom-video__audio',
    errorOverlay: '.batcom-video__overlay',
    playButton: '.batcom-video__button-play',
    infoButton: '.batcom-video__info-icon',
    info: '.batcom-video__info',
  },
  classes: {
      open: 'batcom-video__info--open',
    },
};
